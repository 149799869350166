.content {
  padding: 1.25rem 1.25rem 0;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: rgb(187, 187, 187);
  margin-bottom: 1rem;
}

.item {
  border-top: 0.0625rem solid rgba(182, 165, 166, 0.2);
  padding: 1.25rem 0;
  min-height: 1.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  flex-direction: column;
  align-items: flex-start;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inputsRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.625rem;
  width: 100%;
  justify-content: space-between;
}

.label {
  color: rgb(85, 85, 85);
  font-weight: 600;
}

.footer {
  padding: 0.875rem 1.25rem;
  display: flex;
  justify-content: flex-end;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: rgb(239, 239, 239);
}

.smallInput {
  width: 6.125rem;
}

.tinyInput {
  width: 4.375rem;
}

.right {
  justify-content: flex-end;
}

.marginTop {
  margin-top: 1.375rem;
}
