@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mitr:wght@200;700&display=swap');


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  font-family: "Kanit", sans-serif;
  box-sizing: border-box;
}

:root {
  --primary-color: rgb(219, 82, 77);
  --secondary-color: rgb(70, 142, 145);
  --tertiary-color: rgb(67, 126, 168);
}

button {
  font-family: "Noto Sans Display", sans-serif;
  cursor: pointer;
  border: none;
  box-shadow: none;
  border-radius: 0.25rem;
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
