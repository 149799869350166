.container {
  max-width: 30rem;
  margin: auto;
  text-align: center;
  user-select: none;
}

.content {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1.25rem 0 1.875rem;
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

.secondaryButton {
  font-size: 1rem;
  padding: 0.125rem 0.75rem;
  height: 1.75rem;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  transition: transform 0.1s ease-in-out;
  font-family: 'Kanit';
}

.secondaryActive,
.secondaryButton:active {
  background-color: rgba(0, 0, 0, 0.15);
  transform: translateY(0.125rem);
}

.primaryButton {
  padding: 0 0.75rem;
  box-shadow: rgb(235, 235, 235) 0 0.375rem 0;
  font-size: 1.375rem;
  height: 3.4375rem;
  font-weight: 600;
  min-width: 12.5rem;
  background-color: white;
  text-transform: uppercase;
  transition: color 0.5s ease-in-out 0s;
}

.pomodoro {
  color: var(--primary-color);
}

.short_break {
  color: var(--secondary-color);
}

.long_break {
  color: var(--tertiary-color);
}

.primaryActive {
  transform: translateY(0.375rem);
  box-shadow: none;
}

.actionButtons {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 1.25rem 0 0;
}

.left {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.right {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.nextButton {
  background-color: rgba(0, 0, 0, 0);
  width: 1.75rem;
  height: 1.75rem;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out 0s;
  color: white;
  transform: translateY(6px);
}

.showNext {
  opacity: 1;
  pointer-events: auto;
}

.nextButton:hover {
  opacity: 0.8;
}

.nextButton:active {
  opacity: 0.6;
}

.counter {
  opacity: 0.6;
  margin-bottom: 0.25rem;
}

.footer {
  font-size: 1.125rem;
  font-weight: 400;
}

.time {
  font-size: 6.25rem;
  line-height: 8.6875rem;
  font-weight: 600;
  margin-top: 1.25rem;
  user-select: none;
}

@media screen and (min-width: 992px) {
  .time {
    font-size: 7.5rem;
  }
}
