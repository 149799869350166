.link {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.logo {
  width: 1.25rem;
  height: 1.25rem;
}

.text {
  padding: 0.625rem 0;
  font-size: 1.25rem;
  vertical-align: middle;
}
