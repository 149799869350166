.container {
  display: flex;
  padding: 0 0.75rem;
  max-width: 38.75rem;
  margin: auto;
  width: 100%;
}

.content {
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  height: 3.75rem;
}

.nav {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0.5rem;
  transition: transform 0.1s ease-in-out;
  gap: 0.25rem;
}

.button:hover {
  opacity: 1;
}

.button:active {
  transform: translateY(0.125rem);
}

.label {
  display: none;
}

@media screen and (min-width: 992px) {
  .label {
    display: block;
  }

  .button {
    padding: 0.5rem 0.75rem;
    min-width: 4.375rem;
  }
}
