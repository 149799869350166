.button {
  height: 2rem;
  width: 3.75rem;
  background-color: rgb(204, 204, 204);
  position: relative;
  border-radius: 3.125rem;
  transition: background-color 0.6s ease;
}

.knob {
  position: absolute;
  top: 0.125rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0.0625rem 0.0625rem;
  transition: transform 0.2s ease;
}

.on {
  background-color: rgba(132, 199, 51, 0.8);
}

.on .knob {
  transform: translateX(1.875rem);
}

.off .knob {
  transform: translateX(0.125rem);
}
