.container {
  position: relative;
  display: inline-block;
}

.content {
  border-radius: 0.25rem;
  padding: 0.25rem 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.625rem 1.25rem,
    rgba(0, 0, 0, 0.1) 0 0.1875rem 0.375rem;
  position: absolute;
  background-color: white;
  transform: translateY(0.625rem);
  width: 12.5rem;
  height: fit-content;
  flex-direction: column;
  display: flex;
  top: 100%;
  right: 0;
  z-index: 99;
}

.menuItem {
  border-radius: 0;
  color: rgb(79, 43, 45);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  gap: 0.5rem;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-family: 'Kanit';
}

.menuItem:hover {
  background-color: rgb(241, 238, 238);
}

.imageIcon {
  width: 0.875rem;
  opacity: 0.8;
}
