.container {
  background-color: rgba(0, 0, 0, 0.1);
  height: 0.0625rem;
  margin-bottom: 2.5rem;
}

.progress {
  height: 0.1875rem;
  border-radius: 6.25rem;
  background-color: white;
  transform: translateY(-0.0625rem);
  width: 0%;
}
