.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 300px;
  overflow-y: scroll;
}

.row {
  padding-bottom: 0.5rem;
}

.headerRow {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border-bottom: 0.0625rem solid rgb(235, 235, 235);
  margin-bottom: 1rem;
}

.DataRow {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;  
}

.header {
  width: 100%;
  text-align: start;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: rgb(163, 163, 163);
  font-weight: 600;
  
}

.cell {
  width: 100%;
  text-align: start;
  font-size: 0.75rem;

  color: rgb(163, 163, 163);
  font-weight: 600;
  padding-top: .5rem;
}
