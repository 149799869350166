.container {
  position: relative;
  min-width: 8.125rem;
}

.button {
  background-color: rgba(235, 235, 235);
  color: rgb(120, 120, 120);
  font-size: 0.875rem;
  height: 2.625rem;
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button:active {
  transform: translateY(0.125rem);
}

.content {
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: 0.25rem;
  z-index: 9999999;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.1875rem 0.375rem,
    rgba(0, 0, 0, 0.12) 0 0.125rem 0.25rem;
  margin-top: 0.25rem;
}

.items {
  padding: 0.5rem 0;
}

.item {
  padding: 0.75rem;
  color: rgb(120, 120, 120);
  font-size: 0.875rem;
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  text-align: left;
}

.item:hover,
.selected {
  background-color: rgb(212, 212, 212);
  color: rgb(69, 69, 69);
}
