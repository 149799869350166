.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: var(--primary-color);
  padding-bottom: 0.75rem;
  color: white;
  transition: background-color 0.5s ease-in-out 0s;
}

.short_break {
  background-color: var(--secondary-color);
}

.long_break {
  background-color: var(--tertiary-color);
}

.content {
  padding: 0 0.75rem;
  max-width: 38.75rem;
  width: 100%;
  font-weight: 300;
  display: flex;
  flex-grow: 1;
  align-self: center;
  flex-direction: column;
}
